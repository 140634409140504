import { SUPPORTED_MIME_IMAGE_FORMATS, useGetImage } from '@frontend/api';
import { CircularProgress } from '@mui/material';
import { includes } from 'lodash';
import { useIntl } from 'react-intl';
import { ImageCardWrapper, LoadingWrapper } from './ImageCardWithMagnifier.css';
import { ImageCardWithMagnifierProps } from './ImageCardWithMagnifier.types';
import PlaceholderImage from './PlaceholderImage/PlaceholderImage';

const ImageCardWithMagnifier = ({
  id,
  parentId,
  imageId,
  onClick,
  variant,
  cardsPerRow,
}: ImageCardWithMagnifierProps) => {
  const intl = useIntl();
  const { status, data } = useGetImage(parentId, imageId, variant);

  return (
    <ImageCardWrapper id={id} cardsPerRow={cardsPerRow} variant={variant}>
      {status === 'success' &&
        (includes(
          Object.values(SUPPORTED_MIME_IMAGE_FORMATS),
          data.data?.type,
        ) ? (
          <img
            src={URL.createObjectURL(data.data)}
            alt={imageId}
            loading="lazy"
            onClick={onClick}
          />
        ) : (
          <PlaceholderImage
            icon={'hourglass'}
            caption={intl.formatMessage({
              id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-loading',
            })}
            onClick={onClick}
          />
        ))}
      {status === 'pending' && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}
    </ImageCardWrapper>
  );
};

export default ImageCardWithMagnifier;
