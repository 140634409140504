import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LOGIN_LINK_SOURCE } from './ProtectedRoute.const';
import { IProtectedRoute } from './ProtectedRoute.types';
import useAuth from '../AuthContext/useAuth';
import PlatformHelmet from '../PlatformHelmet/PlatformHelmet';

const ProtectedRoute = ({ allowedRoles, redirectUrl }: IProtectedRoute) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const search = `?source=${LOGIN_LINK_SOURCE}`;
  if (!currentUser || !allowedRoles.includes(currentUser.role)) {
    return (
      <Navigate
        to={`${redirectUrl}${search}`}
        state={{ from: location }}
        replace
      />
    );
  }
  return (
    <>
      <PlatformHelmet />
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
