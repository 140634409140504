import { PLATFORM_ROUTES } from '@frontend/routing';
import {
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useSearchParams,
} from 'react-router-dom';
import useAuth from '../AuthContext/useAuth';
import PlatformHelmet from '../PlatformHelmet/PlatformHelmet';

const PublicRoute = () => {
  const { currentUser, isCurrentUserBuyer } = useAuth();
  const location = useLocation();
  const matchesEmailVerificationPath = useMatch(
    PLATFORM_ROUTES.emailVerification,
  );
  const [searchParams] = useSearchParams();
  const redirectUrl: string = searchParams.get('redirectUrl') ?? '';

  if (currentUser && !matchesEmailVerificationPath) {
    const toPath = redirectUrl
      ? redirectUrl
      : isCurrentUserBuyer
        ? PLATFORM_ROUTES.buyerDashboard
        : PLATFORM_ROUTES.sellerDashboard;

    return <Navigate to={toPath} state={{ from: location }} replace />;
  }

  return (
    <>
      <PlatformHelmet />
      <Outlet />
    </>
  );
};

export default PublicRoute;
