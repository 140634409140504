import {
  ButtonWrapper,
  ImageWrapper,
  MainWrapper,
  TitleSection,
  Wrapper,
} from './ErrorSection.css';
import { RiArrowRightUpLine } from '@remixicon/react';
import { Typography, useTheme } from '@mui/material';
import { Button, InnerTypography } from '@frontend/ui-elements';
import { IErrorSection } from './ErrorSection.types';
import errorImage from '../../../../assets/images/error.png';

export function ErrorSection({
  title,
  description,
  imageSrc = errorImage,
  btnText,
  onClick,
}: IErrorSection) {
  const theme = useTheme();
  return (
    <MainWrapper>
      <Wrapper>
        <TitleSection>
          <Typography
            component="span"
            color={theme.palette.primary[500]}
            variant="h2"
          >
            {title}
          </Typography>

          <Typography variant="h5">
            <InnerTypography
              message={description}
              variant="h5"
            ></InnerTypography>
          </Typography>
        </TitleSection>

        <ButtonWrapper>
          <Button
            buttonType="primary"
            id="error-page.button"
            size="large"
            endIcon={<RiArrowRightUpLine size={24} />}
            onClick={onClick}
          >
            {btnText}
          </Button>
        </ButtonWrapper>
      </Wrapper>

      <ImageWrapper>
        <img src={imageSrc} alt="errorImage" />
      </ImageWrapper>
    </MainWrapper>
  );
}

export default ErrorSection;
