import { Typography } from '@mui/material';
import { formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import { RiArrowUpDownLine } from '@remixicon/react';
import {
  Column,
  DataWrapper,
  MainWrapper,
  OrderValue,
  OrderValueLabel,
} from './RequestOrderCardDetails.css';
import { IRequestOrderCardDetails } from './RequestOrderCardDetails.types';
import { formatDateWithSpaces } from '../../../../../../app/shared/utils/date.utils';
import BillOfLadingDetails from './BillOfLadingDetails/BillOfLadingDetails';
import { RiCalendar2Line } from '@remixicon/react';
import ContainerList from './ContainerList/ContainerList';
import { RiMapPin2Line } from '@remixicon/react';
import { RiMoneyDollarCircleLine } from '@remixicon/react';
import OrderDocuments from '../../DocumentList/OrderDocuments/OrderDocuments';
import PricePill from './PricePill/PricePill';
import { useParams } from 'react-router-dom';

export default function RequestOrderCardDetails({
  order,
  refs: { documentsSectionRef, billOfLadingSectionRef, containerSectionRef },
}: IRequestOrderCardDetails) {
  const { quantity, shippingTo, cost, createdDate } = order;
  const { orderId } = useParams();
  return (
    <MainWrapper>
      <DataWrapper>
        <Column>
          <OrderValue data-testid="weight-info">
            <OrderValueLabel>
              <RiArrowUpDownLine size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.weight" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {formatNumberToUS(quantity)}
              <EmptySpace />
              <Translate id="customer-platform.request-details.order-card.weight-unit" />
            </Typography>
          </OrderValue>
          <OrderValue data-testid="destination-info">
            <OrderValueLabel>
              <RiMapPin2Line size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.destination" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {shippingTo}
            </Typography>
          </OrderValue>
        </Column>

        <Column>
          <OrderValue data-testid="date-info">
            <OrderValueLabel>
              <RiCalendar2Line size={18} />
              <Typography variant="p1" component="span">
                <Translate id="customer-platform.request-details.order-card.date" />
              </Typography>
            </OrderValueLabel>
            <Typography variant="p3" component="span">
              {formatDateWithSpaces(createdDate)}
            </Typography>
          </OrderValue>
          <OrderValue data-testid="price-info">
            {cost ? (
              <>
                <OrderValueLabel>
                  <RiMoneyDollarCircleLine size={18} />
                  <Typography variant="p1" component="span">
                    <Translate id="customer-platform.request-details.order-card.price" />
                  </Typography>
                </OrderValueLabel>
                <Typography variant="p3" component="span">
                  {formatNumberToUS(cost)}
                  <EmptySpace />
                  <Translate id="customer-platform.request-details.order-card.price-unit" />
                </Typography>
              </>
            ) : (
              <PricePill />
            )}
          </OrderValue>
        </Column>
      </DataWrapper>
      <BillOfLadingDetails
        order={order}
        billOfLadingSectionRef={billOfLadingSectionRef}
      />
      <OrderDocuments order={order} documentsSectionRef={documentsSectionRef} />
      {orderId === order.uuid && (
        <ContainerList
          order={order}
          containerSectionRef={containerSectionRef}
        />
      )}
    </MainWrapper>
  );
}
