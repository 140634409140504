import { useUpdateWhatsappConsent } from '@frontend/api';
import { LoaderWrapper } from './AcceptWhatsappConsent.css';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useAuth } from '@frontend/auth';
import { useNavigate } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';

export function AcceptWhatsappConsent() {
  const { mutate: updateWhatsappConsent, status: updateWhatsappConsentStatus } =
    useUpdateWhatsappConsent();

  const { isCurrentUserSeller } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    updateWhatsappConsent();
  }, [updateWhatsappConsent]);

  useEffect(() => {
    if (updateWhatsappConsentStatus === 'success') {
      if (isCurrentUserSeller) {
        navigate(
          {
            pathname: PLATFORM_ROUTES.sellerDashboard,
          },
          { state: { whatsappConsentSuccess: true } },
        );
      } else {
        navigate(
          {
            pathname: PLATFORM_ROUTES.buyerDashboard,
          },
          { state: { whatsappConsentSuccess: true } },
        );
      }
    }
  }, [updateWhatsappConsentStatus, isCurrentUserSeller]);

  return (
    <LoaderWrapper>
      <CircularProgress size={160} />
    </LoaderWrapper>
  );
}

export default AcceptWhatsappConsent;
