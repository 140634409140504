import { Translate } from '@frontend/translation';
import { ToastAlert } from '@frontend/ui-elements';
import { IConfirmationBanner } from './ConfirmationBanner.types';
import { useEffect, useState } from 'react';
import {
  API_STATUSES,
  NOTIFICATION_BANNER_TYPE,
  NOTIFICATION_SOURCE_TYPE,
  useGetNotificationBanner,
  useUpdateNotificationBannerStatus,
} from '@frontend/api';

export default function ConfirmationBanner({ orderId }: IConfirmationBanner) {
  const { status: getNotificationStatus, data: getNotificationResponse } =
    useGetNotificationBanner({
      notificationSourceId: orderId,
      notificationBannerType: NOTIFICATION_BANNER_TYPE.CONFIRMING_DETAILS,
      notificationSourceType: NOTIFICATION_SOURCE_TYPE.ORDER,
    });
  const { mutate: updateNotificationBanner } =
    useUpdateNotificationBannerStatus();
  const [isBannerSeen, setIsBannerSeen] = useState(true);

  useEffect(() => {
    if (getNotificationStatus === API_STATUSES.SUCCESS) {
      if (!getNotificationResponse.data.isSeen) {
        setIsBannerSeen(false);
        updateNotificationBanner({
          isSeen: true,
          uuid: getNotificationResponse?.data?.uuid ?? '',
        });
      }
    }
  }, [
    getNotificationStatus,
    getNotificationResponse,
    updateNotificationBanner,
  ]);

  const currentTitle = (
    <Translate id="customer-platform.listing-details.order-basic-details.confirmation-banner.title" />
  );

  const handleCloseConfirmationBanner = () => {
    setIsBannerSeen(true);
  };
  return (
    !isBannerSeen && (
      <ToastAlert
        title={currentTitle}
        severity="success"
        onClose={handleCloseConfirmationBanner}
      />
    )
  );
}
