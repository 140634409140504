import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { IBillOfLading, IBillOfLadingDataKeys } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Button, TextField } from '@frontend/ui-elements';
import { Form, Row } from './BillOfLadingForm.css';
import {
  IBIllOfLadingForm,
  IBillOfLadingSchema,
} from './BillOfLadingForm.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormTracking } from '../../../../../../../../../app/shared/components/Forms/utils/useFormTracking';
import FormType from '../../../../../../../../../app/shared/components/Forms/utils/FormType.types';

const BillOfLadingForm = ({
  billOfLading,
  onSubmit,
  errors,
  status,
}: IBIllOfLadingForm) => {
  const intl = useIntl();
  const requiredField = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });
  const formId = 'bill-of-lading-form.form';
  const bolSchema = yup.object().shape({
    consigneeName: yup.string().required(requiredField),
    consigneeAddress: yup.string().required(requiredField),
    notifyName: yup.string().required(requiredField),
    notifyAddress: yup.string().required(requiredField),
    materialDescription: yup.string().required(requiredField),
    hsnCode: yup.string().required(requiredField),
    dischargePort: yup.string().required(requiredField),
    finalDestination: yup.string().required(requiredField),
    freeTime: yup.string().required(requiredField),
  });

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    setError,
  } = useForm<FormType<IBillOfLading>>({
    defaultValues: {
      consigneeName: billOfLading?.consigneeName || '',
      consigneeAddress: billOfLading?.consigneeAddress || '',
      consigneeAdditionalInformation:
        billOfLading?.consigneeAdditionalInformation || '',
      notifyName: billOfLading?.notifyName || '',
      notifyAddress: billOfLading?.notifyAddress || '',
      materialDescription: billOfLading?.materialDescription || '',
      hsnCode: billOfLading?.hsnCode || '',
      dischargePort: billOfLading?.dischargePort || '',
      finalDestination: billOfLading?.finalDestination || '',
      freeTime: billOfLading?.freeTime || '',
      additionalRemarks: billOfLading?.additionalRemarks || '',
    },
    resolver: yupResolver<FormType<IBillOfLadingSchema>>(bolSchema),
  });

  useFormTracking({ formId, formErrors, status });

  useEffect(() => {
    if (errors !== undefined) {
      Object.keys(errors).map(field =>
        setError(field as IBillOfLadingDataKeys, {
          type: 'manual',
          message: errors[field as IBillOfLadingDataKeys][0],
        }),
      );
    }
  }, [errors, setError]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      id={formId}
      name={formId}
    >
      <Row>
        <TextField
          id="consignee-name"
          label={
            <Translate id="customer-platform.request-details.bill-of-lading.form.consignee-name" />
          }
          required={true}
          {...register('consigneeName')}
          error={!!formErrors?.consigneeName}
          helperText={formErrors?.consigneeName?.message}
          formId={formId}
        />
        <TextField
          id="consignee-address"
          label={
            <Translate id="customer-platform.request-details.bill-of-lading.form.consignee-address" />
          }
          required={true}
          {...register('consigneeAddress')}
          error={!!formErrors?.consigneeAddress}
          helperText={formErrors?.consigneeAddress?.message}
          formId={formId}
        />
      </Row>
      <TextField
        id="consignee-additional-information"
        label={
          <Translate id="customer-platform.request-details.bill-of-lading.form.consignee-additional-information" />
        }
        {...register('consigneeAdditionalInformation')}
        error={!!formErrors?.consigneeAdditionalInformation}
        helperText={formErrors?.consigneeAdditionalInformation?.message}
        formId={formId}
      />
      <Row>
        <TextField
          id="notify-name"
          label={
            <Translate id="customer-platform.request-details.bill-of-lading.form.notify-name" />
          }
          required={true}
          {...register('notifyName')}
          error={!!formErrors?.notifyName}
          helperText={formErrors?.notifyName?.message}
          formId={formId}
        />
        <TextField
          id="notify-address"
          label={
            <Translate id="customer-platform.request-details.bill-of-lading.form.notify-address" />
          }
          required={true}
          {...register('notifyAddress')}
          error={!!formErrors?.notifyAddress}
          helperText={formErrors?.notifyAddress?.message}
          formId={formId}
        />
      </Row>
      <TextField
        id="material-description"
        label={
          <Translate id="customer-platform.request-details.bill-of-lading.form.material-description" />
        }
        required={true}
        {...register('materialDescription')}
        error={!!formErrors?.materialDescription}
        helperText={formErrors?.materialDescription?.message}
        formId={formId}
      />
      <TextField
        id="hsn-code"
        label={
          <Translate id="customer-platform.request-details.bill-of-lading.form.hsn-code" />
        }
        required={true}
        {...register('hsnCode')}
        error={!!formErrors?.hsnCode}
        helperText={formErrors?.hsnCode?.message}
        formId={formId}
      />
      <Row>
        <TextField
          id="discharge-port"
          label={
            <Translate id="customer-platform.request-details.bill-of-lading.form.discharge-port" />
          }
          required={true}
          {...register('dischargePort')}
          error={!!formErrors?.dischargePort}
          helperText={formErrors?.dischargePort?.message}
          formId={formId}
        />
        <TextField
          id="final-destination"
          label={
            <Translate id="customer-platform.request-details.bill-of-lading.form.final-destination" />
          }
          required={true}
          {...register('finalDestination')}
          error={!!formErrors?.finalDestination}
          helperText={formErrors?.finalDestination?.message}
          formId={formId}
        />
      </Row>
      <TextField
        id="free-time"
        label={
          <Translate id="customer-platform.request-details.bill-of-lading.form.free-time" />
        }
        required={true}
        {...register('freeTime')}
        error={!!formErrors?.freeTime}
        helperText={formErrors?.freeTime?.message}
        formId={formId}
      />
      <TextField
        id="additional-remarks"
        label={
          <Translate id="customer-platform.request-details.bill-of-lading.form.additional-remarks" />
        }
        {...register('additionalRemarks')}
        error={!!formErrors?.additionalRemarks}
        helperText={formErrors?.additionalRemarks?.message}
        formId={formId}
      />
      <Button
        buttonType="primary"
        id="bill-of-lading-form.button.submit"
        size="large"
        isSubmitting={status === 'pending'}
        type="submit"
      >
        {billOfLading ? (
          <Translate id="customer-platform.request-details.bill-of-lading.form.cta.update-bol" />
        ) : (
          <Translate id="customer-platform.request-details.bill-of-lading.form.cta.create-bol" />
        )}
      </Button>
    </Form>
  );
};

export default BillOfLadingForm;
