import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button, EmptySpace } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { List, ListItem, ListWrapper, MainWrapper } from './OrderPhotos.css';
import { IOrderPhotosListItem, OrderPhotosProps } from './OrderPhotos.types';
import OrderPhotosDrawer from './OrderPhotosDrawer/OrderPhotosDrawer';
import ORDER_PHOTOS_ITEM_LIST from './OrderPhotos.const';
import SuccessToast from '../../../../../../../app/shared/components/Toasts/SuccessToast/SuccessToast';

const OrderPhotos = ({
  orderId,
  alreadyUploadedFiles,
  onOrderUpdate,
}: OrderPhotosProps) => {
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState<boolean>(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const headerPortal = document.getElementById('header-toolbar');

  const handleOpenPhotoManager = () => {
    const photoManagerPath = generatePath(PLATFORM_ROUTES.listingOrderPhotos, {
      uuid: uuid as string,
      orderId,
    });
    navigate(photoManagerPath, { replace: true });
  };

  const Item = ({ translationId, required }: IOrderPhotosListItem) => {
    return (
      <ListItem>
        <Typography variant="p3">
          {intl.formatMessage({ id: translationId })}
          {required && (
            <>
              <EmptySpace />*
            </>
          )}
        </Typography>
      </ListItem>
    );
  };

  return (
    <MainWrapper>
      <Typography variant="h5">
        <Translate id="customer-platform.listing-details.order-photos.title" />
      </Typography>

      <ListWrapper>
        <Typography variant="p1">
          <Translate id="customer-platform.listing-details.order-photos.list.title" />
        </Typography>

        <List disablePadding>
          {ORDER_PHOTOS_ITEM_LIST.length > 0 &&
            ORDER_PHOTOS_ITEM_LIST.map((listItem, index) => (
              <Item key={index} {...listItem} />
            ))}
        </List>
      </ListWrapper>

      <OrderPhotosDrawer
        orderId={orderId}
        alreadyUploadedFiles={alreadyUploadedFiles}
        onSuccess={() => {
          setIsSuccessToastOpen(true);
          onOrderUpdate && onOrderUpdate();
        }}
      />
      {headerPortal && (
        <>
          {createPortal(
            <SuccessToast
              isToastOpen={isSuccessToastOpen}
              closeToast={() => setIsSuccessToastOpen(false)}
              titleId="customer-platform.listing-details.order-photos.file-manager.success-toast.title"
              messageId="customer-platform.listing-details.order-photos.file-manager.success-toast.message"
            />,
            headerPortal,
          )}
        </>
      )}

      <Button
        buttonType="primary"
        id={'order-photos.button.open-file-manager'}
        size="large"
        onClick={handleOpenPhotoManager}
      >
        <Translate id="customer-platform.listing-details.order-photos.cta.open-drawer" />
      </Button>
    </MainWrapper>
  );
};

export default OrderPhotos;
