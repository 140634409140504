import { StepIcon, StepIconProps, Typography, useTheme } from '@mui/material';
import {
  EventRowWrapper,
  StepSt,
  StepLabelSt,
  StepContentSt,
} from './RouteEvent.css';
import { RouteEventProps } from './RouteEvent.types';
import { formatUnixTimestampToDateWithSpaces } from '../../../../../../../../../../../../../app/shared/utils/date.utils';
import { RiArrowDownLine } from '@remixicon/react';
import LocationMarker from '../../../LocationMarker/LocationMarker';

export function RouteEvent({
  localizationEvents,
  completed,
  ...stepProps
}: RouteEventProps) {
  const theme = useTheme();
  const { events, label, isCurrent } = localizationEvents;

  const RouteEventContent = () => {
    return (
      events.length > 0 &&
      events.map(event => {
        const { description, timestamp } = event;
        return (
          <EventRowWrapper key={timestamp}>
            <Typography variant="p4">{description}</Typography>
            <Typography variant="p4">
              {formatUnixTimestampToDateWithSpaces(timestamp, true)}
            </Typography>
          </EventRowWrapper>
        );
      })
    );
  };

  return (
    <StepSt {...stepProps} active completed={completed}>
      <StepLabelSt
        StepIconComponent={(stepIconProps: StepIconProps) => (
          <StepIcon
            {...stepIconProps}
            icon={
              <LocationMarker active={completed}>
                {isCurrent && (
                  <RiArrowDownLine
                    size={12}
                    color={theme.palette.primary[500]}
                  />
                )}
              </LocationMarker>
            }
          />
        )}
      >
        {!isCurrent ? (
          <Typography variant="p2">{label}</Typography>
        ) : (
          <RouteEventContent />
        )}
      </StepLabelSt>
      {!isCurrent && events && (
        <StepContentSt>
          <RouteEventContent />
        </StepContentSt>
      )}
    </StepSt>
  );
}

export default RouteEvent;
