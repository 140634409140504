import {
  IContainerTrackingData,
  useGetContainerDetails,
  useGetContainerTrackingData,
} from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { DrawerWithHeader } from '@frontend/ui-elements';
import { CircularProgress } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  ContainerDrawerBodyWrapper,
  LoaderWrapper,
} from './ContainerDrawer.css';
import { ContainerDrawerProps } from './ContainerDrawer.types';
import ContainerDocuments from './ContainerDocuments/ContainerDocuments';
import ContainerInformation from './ContainerInformation/ContainerInformation';
import ContainerImages from './ContainerImages/ContainerImages';
import ContainerTracking from './ContainerTracking/ContainerTracking';
import { some } from 'lodash';

function ContainerDrawer({ containerId, orderId }: ContainerDrawerProps) {
  const intl = useIntl();
  const containerPathMatch = useMatch(PLATFORM_ROUTES.containerDetails);
  const navigate = useNavigate();
  const { uuid: requestId } = useParams();

  const isContainerDrawerOpen =
    !!containerPathMatch &&
    containerPathMatch.params.containerId === containerId;

  const { status: getContainerDetailsStatus, data: containerDetailsResponse } =
    useGetContainerDetails(containerId);

  const {
    status: getContainerTrackingDataStatus,
    data: getContainerTrackingDataResponse,
  } = useGetContainerTrackingData(containerId);

  const handleCloseContainerDrawer = useCallback(() => {
    const orderDetailsUrl = generatePath(PLATFORM_ROUTES.orderDetails, {
      uuid: requestId as string,
      orderId: orderId as string,
    });
    navigate(orderDetailsUrl, { replace: true });
  }, [navigate, orderId, requestId]);

  if (getContainerDetailsStatus === 'success') {
    const {
      containerNumber,
      documents,
      sealNumber,
      netWeight,
      tare,
      total,
      loadingPhotos,
      uuid,
    } = containerDetailsResponse.data;

    const containerInformation = {
      containerNumber,
      sealNumber,
      netWeight,
      tare,
      total,
    };

    const removeLocationsWithoutEvents = (tracking: IContainerTrackingData) => {
      const { routeEvents, locations } = tracking;
      return {
        ...tracking,
        locations: locations.filter(location =>
          some(routeEvents, {
            locationId: location.id,
          }),
        ),
      } as IContainerTrackingData;
    };

    const tracking =
      getContainerTrackingDataStatus === 'success'
        ? removeLocationsWithoutEvents(getContainerTrackingDataResponse.data)
        : undefined;

    return (
      <DrawerWithHeader
        headerLine={`${intl.formatMessage({
          id: 'customer-platform.request-details.order-list.container-drawer.header',
        })} ${containerNumber}`}
        id="container-drawer"
        onClose={handleCloseContainerDrawer}
        open={isContainerDrawerOpen}
      >
        <ContainerDrawerBodyWrapper>
          <ContainerInformation {...containerInformation} />
          <ContainerDocuments
            documents={documents ?? []}
            uuid={uuid}
            containerNumber={containerNumber}
          />
          <ContainerImages
            containerId={containerId}
            imageList={loadingPhotos}
            containerNumber={containerNumber}
          />
          <ContainerTracking tracking={tracking} />
        </ContainerDrawerBodyWrapper>
      </DrawerWithHeader>
    );
  }

  return (
    <DrawerWithHeader
      headerLine={intl.formatMessage({
        id: 'customer-platform.request-details.order-list.container-drawer.header',
      })}
      id="container-drawer"
      onClose={handleCloseContainerDrawer}
      open={isContainerDrawerOpen}
    >
      <LoaderWrapper>
        <CircularProgress size={120} />
      </LoaderWrapper>
    </DrawerWithHeader>
  );
}

export default ContainerDrawer;
