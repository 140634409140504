import { useAuth } from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { DrawerWithHeader, Logo } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  AppBar,
  Container,
  HeaderDrawerWrapper,
  Link,
  Toolbar,
} from './PlatformHeader.css';
import { HEADER_VARIANTS, PlatformHeaderProps } from './PlatformHeader.types';
import useHeader from './useHeader/useHeader';
import LogoutModal from './LogoutModal/LogoutModal';
import SuccessToast from '../Toasts/SuccessToast/SuccessToast';
import HeaderBurgerMenu from './HeaderBurgerMenu/HeaderBurgerMenu';
import HeaderDesktopMenu from './HeaderDesktopMenu/HeaderDesktopMenu';
import VariantForm from '../Forms/VariantForm/VariantForm';

export default function PlatformHeader({ variant }: PlatformHeaderProps) {
  const { isAuthenticated } = useAuth();

  const theme = useTheme();
  const intl = useIntl();
  const isTabletOrLess = useMediaQuery(theme.breakpoints.down('tabletL'));
  const userVariantPath =
    variant === HEADER_VARIANTS.BUYER ? 'request' : 'listing';

  const {
    currentOpenElement,
    openBurgerDrawer,
    openFormDrawer,
    openDropdownMenu,
    openLogoutModal,
    openToast,
    closeElement,
    closeToast,
    isBurgerDrawerOpen,
    isFormDrawerOpen,
    isDropdownMenuOpen,
    isLogoutModalOpen,
    isToastOpen,
  } = useHeader();

  const navigateToVariantHome = () => {
    return variant === HEADER_VARIANTS.BUYER
      ? PLATFORM_ROUTES.buyerDashboard
      : variant === HEADER_VARIANTS.SELLER
        ? PLATFORM_ROUTES.sellerDashboard
        : import.meta.env.VITE_STATIC_APP_URL;
  };

  const closeFormAndOpenToast = () => {
    closeElement();
    openToast();
  };

  return (
    <AppBar>
      <Container>
        <Toolbar id="header-toolbar">
          <Link id="user-header.link.logo" to={navigateToVariantHome()}>
            <Logo />
          </Link>
          {isTabletOrLess ? (
            <HeaderBurgerMenu
              closeElement={closeElement}
              isBurgerDrawerOpen={isBurgerDrawerOpen}
              isLogoutModalOpen={isLogoutModalOpen}
              openBurgerDrawer={openBurgerDrawer}
              openFormDrawer={openFormDrawer}
              openLogoutModal={openLogoutModal}
              variant={variant}
            />
          ) : (
            <HeaderDesktopMenu
              closeElement={closeElement}
              currentOpenElement={currentOpenElement}
              isDropdownMenuOpen={isDropdownMenuOpen}
              openDropdownMenu={openDropdownMenu}
              openFormDrawer={openFormDrawer}
              openLogoutModal={openLogoutModal}
              variant={variant}
            />
          )}

          {isAuthenticated && (
            <DrawerWithHeader
              headerLine={intl.formatMessage({
                id: `customer-platform.header.${userVariantPath}-form.heading`,
              })}
              id="user-header.form-drawer"
              onClose={closeElement}
              open={isFormDrawerOpen}
            >
              <HeaderDrawerWrapper>
                <VariantForm
                  onSubmit={closeFormAndOpenToast}
                  variant={variant}
                />
              </HeaderDrawerWrapper>
            </DrawerWithHeader>
          )}

          {isLogoutModalOpen && (
            <LogoutModal
              onClose={isTabletOrLess ? openBurgerDrawer : closeElement}
            />
          )}

          {isToastOpen && (
            <SuccessToast
              isToastOpen={isToastOpen}
              closeToast={closeToast}
              titleId={`customer-platform.shared.components.user-header.success-toast.${userVariantPath}-form.title`}
              messageId={`customer-platform.shared.components.user-header.success-toast.${userVariantPath}-form.message`}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
