import { BrowserOptions } from '@sentry/react';

const sentryConfig = {
  dsn: import.meta.env.VITE_SENTRY_DSN || '',
  environment: import.meta.env.VITE_ENVIRONMENT || '',
  integrations: [],
  sampleRate: 0.5,
} as BrowserOptions;

export default sentryConfig;
