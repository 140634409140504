import { useAuth } from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoaderWrapper } from './LoginValidation.css';
import { RE_LOGIN_SOURCE } from './LoginValidation.const';
import { ICreateSessionRequest } from '@frontend/api';

export function LoginValidation() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const {
    login,
    authenticationInProgress,
    authenticationNotStarted,
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (login) {
      const loginSource: string = searchParams.get('login_source') ?? '';
      const params: ICreateSessionRequest = {
        ...(token && { token }),
        ...(loginSource && { loginSource }),
      };
      login(params);
    }
  }, [token, searchParams, login]);

  useEffect(() => {
    if (!authenticationInProgress && !authenticationNotStarted) {
      // If user fails login (Login link expired) redirect to page and show expired modal
      if (!currentUser) {
        const sourceUrl: string = searchParams.get('sourceUrl') ?? '';
        if (sourceUrl !== '') {
          navigate({
            pathname: sourceUrl,
            search: `?source=${RE_LOGIN_SOURCE}`,
          });
        } else {
          navigate({
            pathname: PLATFORM_ROUTES.buyMetal,
            search: `?source=${RE_LOGIN_SOURCE}`,
          });
        }
      }
    }
  }, [
    authenticationInProgress,
    authenticationNotStarted,
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
    navigate,
    searchParams,
  ]);

  if (authenticationInProgress || authenticationNotStarted) {
    return (
      <LoaderWrapper>
        <CircularProgress size={160} />
      </LoaderWrapper>
    );
  }
}

export default LoginValidation;
