import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { trackFormSubmitEvent } from '@frontend/tracking';
import { MutationStatus } from '@tanstack/react-query';

interface FormTrackingProps {
  formId: string;
  formErrors: unknown;
  status: MutationStatus;
}
export const useFormTracking = ({
  formId,
  formErrors,
  status,
}: FormTrackingProps) => {
  useEffect(() => {
    if (!isEmpty(formErrors)) {
      trackFormSubmitEvent({
        formId,
        formName: formId,
        formStatus: 'error',
      });
    }
  }, [formErrors, formId]);

  useEffect(() => {
    if (status === 'success') {
      trackFormSubmitEvent({
        formId,
        formName: formId,
        formStatus: 'success',
      });
    }
  }, [status, formId]);
};
