import { Reducer } from 'react';
import {
  IEmailVerificationFailedAction,
  IEmailVerificationProgressAction,
  IEmailVerificationSuccessAction,
  ILoginFailedAction,
  ILoginProgressAction,
  ILoginSuccessAction,
  ILogoutAction,
  IState,
} from './AuthContext.types';

export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_PROGRESS = 'login_progress';
export const LOGIN_FAILED = 'login_failed';
export const LOGOUT = 'logout';
export const EMAIL_VERIFICATION_PROGRESS = 'email_verification_progress';
export const EMAIL_VERIFICATION_FAILED = 'email_verification_failed';
export const EMAIL_VERIFICATION_SUCCESS = 'email_verification_success';

type TAction =
  | ILoginSuccessAction
  | ILoginProgressAction
  | ILogoutAction
  | ILoginFailedAction
  | IEmailVerificationProgressAction
  | IEmailVerificationFailedAction
  | IEmailVerificationSuccessAction;

export const authReducer: Reducer<IState, TAction> = (state, action) => {
  const { type } = action;
  switch (type) {
    case LOGIN_PROGRESS:
      return {
        ...state,
        currentUser: undefined,
        authenticationStatus: 'pending',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticationStatus: 'success',
        currentUser: action.payload,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        authenticationStatus: 'error',
        currentUser: undefined,
      };
    case LOGOUT:
      return {
        ...state,
        authenticationInProgress: 'idle',
        currentUser: undefined,
      };
    case EMAIL_VERIFICATION_PROGRESS:
      return {
        ...state,
        emailVerificationStatus: 'pending',
      };
    case EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        emailVerificationStatus: 'error',
      };
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationStatus: 'success',
      };
    default:
      return state;
  }
};
