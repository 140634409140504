import { ReactNode, useEffect } from 'react';
import useAuth from '../AuthContext/useAuth';
import { useMatch } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { LoaderWrapper } from './CheckExistingSession.css';
import { CircularProgress } from '@mui/material';

export interface ICheckExistingSession {
  children: ReactNode;
}

export default function CheckExistingSession({
  children,
}: ICheckExistingSession) {
  const { checkSession, authenticationInProgress, authenticationNotStarted } =
    useAuth();
  const isLoginValidationRoute = useMatch(PLATFORM_ROUTES.loginValidation);

  useEffect(() => {
    !isLoginValidationRoute && checkSession && checkSession();
  }, [checkSession, isLoginValidationRoute]);

  if (
    !isLoginValidationRoute &&
    (authenticationNotStarted || authenticationInProgress)
  ) {
    return (
      <LoaderWrapper>
        <CircularProgress size={160} />
      </LoaderWrapper>
    );
  }
  return children;
}
