export const USER_ROLE_TYPE_ENUM = {
  BUYER: 'company.role.buyer',
  SELLER: 'company.role.seller',
  SELLER_AND_BUYER: 'company.role.seller_and_buyer',
} as Record<string, string>;

export const CompanyVerificationStatus = [
  'company.verification.approved',
  'company.verification.profile_incomplete',
  'company.verification.needs_review',
  'company.verification.additional_info_required',
  'company.verification.declined',
  'company.verification.archive',
  'company.verification.processed',
] as const;
