import { trackModalViewEvent } from '@frontend/tracking';
import { useCallback, useState } from 'react';
import { TModals } from './useModal.types';

const useModal = () => {
  const [currentOpenModal, setCurrentOpenModal] = useState<TModals | undefined>(
    undefined,
  );

  const handleTrackModalViewEvent = (modalBox: TModals) => {
    trackModalViewEvent({
      elementId: `${modalBox}-modal.open-modal`,
    });
  };

  const openInfoModal = useCallback(() => {
    handleTrackModalViewEvent('info');
    setCurrentOpenModal('info');
  }, []);

  const openLoginModal = useCallback(() => {
    handleTrackModalViewEvent('login');
    setCurrentOpenModal('login');
  }, []);

  const openLinkExpiredModal = useCallback(() => {
    handleTrackModalViewEvent('linkExpired');
    setCurrentOpenModal('linkExpired');
  }, []);

  const openLinkLoginModal = useCallback(() => {
    handleTrackModalViewEvent('linkLogin');
    setCurrentOpenModal('linkLogin');
  }, []);

  const openSuccessModal = useCallback(() => {
    handleTrackModalViewEvent('success');
    setCurrentOpenModal('success');
  }, []);

  const openEmailVerificationModal = useCallback(() => {
    handleTrackModalViewEvent('emailVerification');
    setCurrentOpenModal('emailVerification');
  }, []);

  const openEmailVerificationLinkExpiredModal = useCallback(() => {
    handleTrackModalViewEvent('emailVerificationLinkExpired');
    setCurrentOpenModal('emailVerificationLinkExpired');
  }, []);

  const closeModal = () => setCurrentOpenModal(undefined);
  const isInfoModalOpen = currentOpenModal === 'info';
  const isLoginModalOpen = currentOpenModal === 'login';
  const isLinkLoginModalOpen = currentOpenModal === 'linkLogin';
  const isSuccessModalOpen = currentOpenModal === 'success';
  const isLinkExpiredModalOpen = currentOpenModal === 'linkExpired';
  const isEmailVerificationModalOpen = currentOpenModal === 'emailVerification';
  const isEmailVerificationLinkExpiredModalOpen =
    currentOpenModal === 'emailVerificationLinkExpired';

  return {
    openInfoModal,
    openLoginModal,
    openLinkExpiredModal,
    openLinkLoginModal,
    openSuccessModal,
    openEmailVerificationModal,
    openEmailVerificationLinkExpiredModal,
    closeModal,
    isInfoModalOpen,
    isLoginModalOpen,
    isSuccessModalOpen,
    isLinkExpiredModalOpen,
    isLinkLoginModalOpen,
    isEmailVerificationModalOpen,
    isEmailVerificationLinkExpiredModalOpen,
  };
};

export default useModal;
