import { formatNumberToUS } from '@frontend/utils';
import { Translate } from '@frontend/translation';
import { AccordionSummary, Button, EmptySpace } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ButtonWrapper,
  ListingAccordion,
  ListingAccordionDetails,
  ListingCardWrapper,
  ListingDetails,
  ListingDetailsItem,
  ListingNoAndDateWrapper,
  ListingOverview,
} from './ListingCard.css';
import { IListingCardProps } from './ListingCard.types';
import { formatDateWithDots } from '../../../../../app/shared/utils/date.utils';
import {
  RiArrowUpDownLine,
  RiFoldersLine,
  RiMapPinLine,
  RiMoneyDollarCircleLine,
  RiRecycleLine,
} from '@remixicon/react';
import NullableCardDetailsItem from '../../../../../app/shared/components/Cards/NullableCardDetailsItem/NullableCardDetailsItem';
import StatusPill from '../../../../../app/shared/components/Cards/StatusPill/StatusPill';
import { useCardScroll } from '../../../../../app/shared/components/useCardScroll/useCardScroll';

export default function ListingCard({
  listing: {
    status,
    publicId,
    listingNumberId,
    quantity,
    metalType,
    incoterms,
    shippingFrom,
    createdDate,
    cost,
  },
  expanded,
  onClick,
  enableScroll,
}: IListingCardProps) {
  const theme = useTheme();
  const isDesktopOrBigger = useMediaQuery(theme.breakpoints.up('desktop'));
  const isTabletOrSmaller = !isDesktopOrBigger;
  const { cardRef } = useCardScroll({
    enableScroll,
  });

  return (
    <ListingCardWrapper>
      <ListingAccordion
        expanded={expanded}
        id={`listing-card.accordion.${publicId}`}
        onClick={isDesktopOrBigger && !expanded ? onClick : undefined}
        square
        ref={cardRef}
      >
        <AccordionSummary showActionButton={isDesktopOrBigger}>
          <ListingOverview>
            <ListingNoAndDateWrapper>
              <Typography variant="h6">
                <Translate
                  id="customer-platform.listing-list.listing-card.order-number"
                  values={{
                    LISTING_NUMBER_ID: listingNumberId,
                  }}
                />
              </Typography>
              <Typography variant="caption4" component="span">
                <Translate
                  id="customer-platform.listing-list.listing-card.created-date"
                  values={{
                    CREATION_DATE: formatDateWithDots(createdDate),
                  }}
                />
              </Typography>
            </ListingNoAndDateWrapper>
            <StatusPill status={status} />
          </ListingOverview>
        </AccordionSummary>
        <ListingAccordionDetails>
          <ListingDetails>
            <ListingDetailsItem data-testid="test.listing-weight-information">
              <RiArrowUpDownLine size={16} />
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.listing-list.listing-card.weight" />
              </Typography>
              <NullableCardDetailsItem data={quantity}>
                <Typography variant="caption2" component="span">
                  {formatNumberToUS(quantity as number)}
                  <EmptySpace />
                  <Translate id="customer-platform.listing-list.listing-card.weight-unit" />
                </Typography>
              </NullableCardDetailsItem>
            </ListingDetailsItem>
            <ListingDetailsItem data-testid="test.listing-metal-type">
              <RiRecycleLine size={16} />
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.listing-list.listing-card.metal-type" />
              </Typography>
              <Typography variant="caption2" component="span">
                <Translate id={`customer-platform.api.${metalType}`} />
              </Typography>
            </ListingDetailsItem>
            <ListingDetailsItem data-testid="test.listing-incoterm-information">
              <RiFoldersLine size={16} />
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.listing-list.listing-card.incoterms" />
              </Typography>
              <NullableCardDetailsItem data={incoterms}>
                <Typography variant="caption2" component="span">
                  <Translate id={`customer-platform.api.${incoterms}`} />
                </Typography>
              </NullableCardDetailsItem>
            </ListingDetailsItem>
            <ListingDetailsItem data-testid="test.listing-shipping-from-information">
              <RiMapPinLine size={16} />
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.listing-list.listing-card.shipping-from" />
              </Typography>
              <NullableCardDetailsItem data={shippingFrom}>
                <Typography variant="caption2" component="span">
                  {shippingFrom}
                </Typography>
              </NullableCardDetailsItem>
            </ListingDetailsItem>
            <ListingDetailsItem data-testid="test.listing-cost-information">
              <RiMoneyDollarCircleLine size={16} />
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.listing-list.listing-card.cost" />
              </Typography>
              <Typography variant="caption2" component="span">
                {cost ? (
                  <>
                    {formatNumberToUS(cost as number)}
                    <EmptySpace />
                    <Translate id="customer-platform.listing-list.listing-card.cost-unit" />
                  </>
                ) : (
                  <Translate id="customer-platform.listing-list.listing-card.cost-unknown" />
                )}
              </Typography>
            </ListingDetailsItem>
          </ListingDetails>
          {isTabletOrSmaller && (
            <ButtonWrapper>
              <Button
                buttonType="primary"
                id={`listing-card.button.open-listing-${publicId}`}
                size="small"
                onClick={onClick}
              >
                <Translate id="customer-platform.listing-list.listing-card.cta" />
              </Button>
            </ButtonWrapper>
          )}
        </ListingAccordionDetails>
      </ListingAccordion>
    </ListingCardWrapper>
  );
}
