import { ISellerOrderDownloadFileData } from '@frontend/api';
import { Typography, useTheme } from '@mui/material';
import { ChangeEvent, Fragment, KeyboardEventHandler } from 'react';
import {
  Container,
  FileHelperContainer,
  FileSelectorLabel,
  FileSelectorWrapper,
  FileSeparator,
} from './FilePicker.css';
import { IFilePicker, IFilePickerFile } from './FilePicker.types';
import { RiFileUploadLine } from '@remixicon/react';
import UploadedFile from './UploadedFile/UploadedFile';

export default function FilePicker({
  errorMessageList,
  fileUploadButtonText,
  helperText,
  id = 'file-picker',
  label,
  onChange,
  required,
  acceptedFormats,
  value: localFiles = [],
  invalidValues: invalidFiles = [],
  remoteValues: remoteFiles = [],
  onClickDeleteFromRemote,
  onClickDownload,
  deleteList,
}: IFilePicker) {
  const theme = useTheme();

  const values = [
    ...localFiles.map(file => ({
      name: file.name,
      size: file.size,
      type: file.type,
    })),
    ...remoteFiles.map(remoteFile => ({
      name:
        remoteFile.title ??
        `${remoteFile.uniqueIdentifier}.${remoteFile.fileType}`,
      type: remoteFile.fileType as string,
      uniqueIdentifier: remoteFile.uniqueIdentifier,
    })),
    ...invalidFiles,
  ] as IFilePickerFile[];

  const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    files && onChange([...localFiles, ...Array.from(files)]);
  };

  const deleteLocalFile = (index: number) => {
    onChange(
      localFiles.filter((_, i) => {
        return index !== i;
      }),
    );
  };

  const deleteRemoteFile = (uniqueIdentifier: string) => {
    onClickDeleteFromRemote && onClickDeleteFromRemote(uniqueIdentifier);
  };

  const downloadRemoteFile = (file: ISellerOrderDownloadFileData) => {
    onClickDownload && onClickDownload(file);
  };

  const invokeFileSelectorByKeyboard: KeyboardEventHandler<
    HTMLLabelElement
  > = event => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault(); // Prevent default action for space/Enter
      document.getElementById(id)?.click(); // Trigger the file input
    }
  };

  return (
    <Container>
      <FileSelectorWrapper>
        <Typography variant="p1">
          {label}
          {required ? '*' : ''}
        </Typography>
        <FileSelectorLabel
          tabIndex={0}
          role={'button'}
          htmlFor={id}
          onKeyDown={invokeFileSelectorByKeyboard}
        >
          <RiFileUploadLine size={24} color={theme.palette.primary[500]} />
          <Typography variant="p1" color={theme.palette.primary[500]}>
            {fileUploadButtonText}
          </Typography>
          <input
            id={id}
            type="file"
            accept={acceptedFormats?.map(format => `.${format}`).join(',')}
            multiple={true}
            onChange={handleFileEvent}
          />
        </FileSelectorLabel>
      </FileSelectorWrapper>

      {helperText && (
        <FileHelperContainer>
          <Typography variant="p3" color={theme.palette.error[500]}>
            {helperText}
          </Typography>
        </FileHelperContainer>
      )}

      {values &&
        values.map((file, index) => {
          return (
            !deleteList?.includes(file.uniqueIdentifier as string) && (
              <Fragment key={index}>
                <UploadedFile
                  file={file}
                  onClickDelete={
                    'uniqueIdentifier' in file
                      ? () => deleteRemoteFile(file.uniqueIdentifier as string)
                      : () => deleteLocalFile(index)
                  }
                  onClickDownload={
                    'uniqueIdentifier' in file && downloadRemoteFile
                      ? () =>
                          downloadRemoteFile({
                            uniqueIdentifier: file.uniqueIdentifier,
                            fileName: file.name,
                            fileType: file.type,
                          } as ISellerOrderDownloadFileData)
                      : undefined
                  }
                  error={
                    Boolean(errorMessageList && index in errorMessageList) ||
                    Boolean(file.errorMessage)
                  }
                  errorMessage={
                    errorMessageList && index in errorMessageList
                      ? errorMessageList[index]?.message
                      : file.errorMessage
                  }
                />
                {index + 1 < values.length && <FileSeparator />}
              </Fragment>
            )
          );
        })}
    </Container>
  );
}
