import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetCurrentUserResponse,
  IGetCurrentUserResponseFailure,
} from './getCurrentUser.types';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

const submitGetCurrentUser = (apiUrl: string) => {
  return axiosClient.get(`${apiUrl}/accounts/user/info/`);
};

const useGetCurrentUserMutation = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse<IGetCurrentUserResponse>,
    AxiosError<IGetCurrentUserResponseFailure>
  >({
    mutationFn: () => submitGetCurrentUser(apiUrl),
  });
};

export default useGetCurrentUserMutation;
