import { styled } from '@frontend/theme';
import {
  IconButton as BaseIconButton,
  MenuItem as MenuItemBase,
} from '@frontend/ui-elements';
import { Box as MUIBox, Menu as MUIMenu } from '@mui/material';

export const GuessButtonWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const Menu = styled(MUIMenu)(() => ({
  '& .MuiPaper-root': {
    minWidth: '140px',
    marginTop: '16px',
    boxShadow: '0px 4px 12px 0px rgba(36, 36, 36, 0.08)',
  },
}));

export const MenuItem = styled(MenuItemBase)(() => ({
  gap: '8px',
  padding: '0 8px',
}));

export const UserIconButton = styled(BaseIconButton)(() => ({
  alignItems: 'center',
  borderRadius: 0,
  padding: 0,
  gap: '8px',
}));

export const UserNavbar = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '40px',
}));
